.form-check {
  display: flex;
  align-items: center;
  gap: 8px;

  position: relative;

  * {
    cursor: pointer;
    user-select: none;
  }

  input.form-check-input {
    height: 36px * $scale;
    width: 24px * $scale;

    border: 3px solid $light-grey;
    background-color: transparent;

    &:focus {
      border: 3px solid $black !important;
    }

    &:checked {
      border: 3px solid $black;
      background: $black;
      color: $white;
      /*
      & + label.form-check-label::after {
        content: "check";
        font-family: "Material Symbols Outlined";
        color: $light-grey;
        position: absolute;
        font-size: 23px * $scale;
        left: -30px;
      }*/
      &::after {
        content: "check";
        font-family: "Material Symbols Outlined";
        color: $light-grey;
        position: absolute;
        font-size: 30px * $scale;
        left: 3px;
      }
    }
  }

  label.form-check-label {
    transform: translateY(2px);
    font-family: $font-primary;
  }
}
