.multi-select-container {
  display: flex;
  align-items: center;
  overflow-x: auto;
  white-space: nowrap;
  scrollbar-width: none;
}

.multi-select-item {
  cursor: pointer;
  display: inline-block;
  padding: 10px;
  border: 1px solid #ccc;
  margin: 5px;
  border-radius: 5px;
  user-select: none;
  transition: background-color 0.3s;
  &:hover {
    background-color: $light-grey;
  }
}

.multi-select-item.selected {
  background-color: $light-grey;
  border: 1px solid $dark-grey;
}

.multi-select-selector {
  position: absolute;
  background-color: rgba(0, 0, 0, 0.1);
  pointer-events: none;
  border-radius: 5px;
}
