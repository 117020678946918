*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

#root,
body,
html {
  width: 100vw;
  max-width: 100vw;
  overflow-x: hidden;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  footer {
    margin-top: auto;
  }
}

html,
body {
  overscroll-behavior-x: none;
}

.hide-scroll-bar {
  * {
    -ms-overflow-style: none !important; /* Internet Explorer 10+ */
    scrollbar-width: none !important; /* Firefox */
  }
  *::-webkit-scrollbar {
    display: none !important; /* Safari and Chrome */
  }
}

@import '../TakyonDesignSystem/scss/index';
@import 'react-toastify/dist/ReactToastify.css';
@import 'react-circular-progressbar/dist/styles.css';

@import './_App/pages/app';
@import './_App/pages/swipe';
@import './_App/pages/auth';

@import './_Web/pages/partners';
@import './_Web/pages/hub';
@import './_Web/pages/metasearch';

@import './_Web/components/_loader';

@import './_Shared/components/modal.scss';
@import './_Shared/components/onboarding.scss';
@import 'react-loading-skeleton/dist/skeleton.css';

@import 'react-date-range/dist/styles.css';
@import 'react-date-range/dist/theme/default.css';

.rdrDefinedRangesWrapper {
  display: none;
}

.rdrMonth,
.rdrCalendarWrapper,
.rdrMonths,
.rdrDays {
  width: 100%;
  height: 100%;
}

// .rdrDateDisplayWrapper {
//   display: none;
// }

.cursor-pointer {
  cursor: pointer;
}

a {
  cursor: pointer;
}

p {
  margin: 0 !important;
}

.h100 {
  height: 100%;
}
.w100 {
  width: 100%;
}
.min-h100 {
  min-height: 100%;
}
.min-w100 {
  min-width: 100%;
}
.min-hfull {
  min-height: 100vh;
}
.nowrap {
  white-space: nowrap;
}

.only-mobile {
  @include media-breakpoint-up(md) {
    display: none !important;
  }

  &.only-mobile-lg {
    display: flex !important;
    @include media-breakpoint-up(lg) {
      display: none !important;
    }
  }
}

.only-desktop {
  @include media-breakpoint-down(md) {
    display: none !important;
  }

  &.only-desktop-lg {
    @include media-breakpoint-down(lg) {
      display: none !important;
    }
  }
}

.mobile {
  @include media-breakpoint-down(md) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

.desktop {
  @include media-breakpoint-up(md) {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
}

.horizontal-divider {
  width: 100%;
  border-top: 0.5px solid $grey-line;
  margin: 40px 0;
}

.vertical-divider {
  height: 100%;
  border-left: 0.5px solid $grey-line;
  margin: 0 40px;
}

.text-md-left {
  @include media-breakpoint-up(md) {
    text-align: left !important;
  }
}

.circle {
  border-radius: 100%;
}

.shadow {
  box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.2);
}
.shadow-light {
  box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.05);
}
.shadow-white {
  box-shadow: 2px 2px 10px 1px rgba(255, 255, 255, 0.5);
}
.text-shadow {
  text-shadow: black 0 0 10px;
}

.hover-select {
  position: relative;
  overflow: hidden;

  &::after {
    content: '';
    position: absolute;
    background-color: rgba(0, 0, 0, 0.3);
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 2;
    transition-duration: 0.3s;
  }

  &:hover {
    &::after {
      opacity: 0;
    }
  }
}

.underline {
  text-decoration: underline;
}

.hover-underline {
  &:hover {
    text-decoration: underline;
  }
}

.text-bwh {
  color: #164394;
}

.list-none {
  ul {
    list-style-type: none;

    padding: 0;
  }
}

.vh100-fix {
  height: calc(100dvh - 200px);
  @include media-breakpoint-up(lg) {
    height: 100dvh;
  }
}

.text-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  text-wrap: nowrap;
}

.rotate-infinite {
  animation: rotate-infinite 1s infinite linear;
}

@keyframes rotate-infinite {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.react-loading-skeleton {
  border-radius: 20px;
}

.bg-white-50 {
  background-color: rgba(255, 255, 255, 0.65);
}

.rounded {
  border-radius: 20px !important;
}
