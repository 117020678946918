progress[value] {
  -webkit-appearance: none;
  appearance: none;

  height: 7px;

  &::-webkit-progress-bar {
    background-color: $black;
    border-radius: 7px;
  }

  &::-webkit-progress-value {
    border-radius: 7px;
    background-color: $primary;
  }

  &.light {
    &::-webkit-progress-value {
      background-color: $black;
    }
    &::-webkit-progress-bar {
      background-color: $primary;
    }
  }

  &.inverted {
    &::-webkit-progress-bar {
      background-color: $primary;
      border-radius: 7px;
    }

    &::-webkit-progress-value {
      border-radius: 7px;
      background-color: $black;
    }
  }
}
