.partners-page-container {
  display: flex;
  flex-direction: column;

  position: relative;

  width: 100%;
  height: 100%;
  text-align: center;

  .map-container {
    width: 100%;
    height: 100%;

    @media only screen and (max-width: '765px') {
      margin: 0;
      width: 100vw;
    }
  }

  .map-info-container {
    display: flex;
    flex-direction: column;
    max-width: 300px;
    min-width: 250px;

    .logo {
      height: 35px;
    }

    .hotel-name {
      font-size: 18px;
      font-weight: 500;
    }

    .stars {
      display: flex;
      justify-content: center;
      margin: 0;

      .star {
        font-size: 20px;
        color: $gold;
        font-variation-settings: 'FILL' 1;
      }
    }
  }

  .partner-overlay {
    position: absolute;
    left: 100px;
    top: 100px;
    bottom: 35px;
    width: 100%;
    max-width: 380px;
    z-index: 1;

    @media only screen and (max-width: '765px') {
      width: unset;
      max-width: unset;
      right: 15px;
      left: 15px;

      bottom: unset;
    }

    .search {
      @media only screen and (max-width: '765px') {
        margin-right: 30px;
      }

      position: relative;

      .css-13cymwt-control {
        border-radius: 10px;
      }

      input {
        font-size: 16px !important;
        border: none !important;
      }

      .close {
        display: flex;
        justify-content: center;
        transform: translateY(-50%);
        top: 50%;
        right: -35px;

        position: absolute;
        display: flex;

        background-color: $white;
        border-radius: 100%;

        height: 25px;
        width: 25px;

        color: $dark-grey;

        cursor: pointer;
      }
    }

    .partner-scroll-container {
      position: relative;

      box-shadow: 0px 0px 10px 10px rgba(0, 0, 0, 0.07);

      &.scrolling::after {
        content: '';
        position: absolute;

        right: 0;
        top: 0;
        left: 0;

        box-shadow: 0px 0px 10px 10px rgba(0, 0, 0, 0.07);
      }

      margin-top: 25px;
      background-color: $white;
      border-radius: 10px;
      overflow: hidden;

      max-height: calc(70vh - 25px);
      @media only screen and (max-width: '765px') {
        max-height: 50vh;
      }

      .partner-scroll {
        max-height: calc(65vh - 25px);
        @media only screen and (max-width: '765px') {
          max-height: 50vh;
        }
        overflow-y: scroll;

        @media only screen and (max-width: '765px') {
          &.closed {
            height: 0;
          }
        }
      }
    }

    .partner-box {
      display: flex;
      gap: 15px;

      max-width: 100%;
      width: 100%;

      overflow: hidden;

      border-bottom: 1px solid $light-grey;
      padding: 25px 15px;

      cursor: pointer;

      &:hover {
        background-color: $white;
      }

      .cta {
        margin-top: 15px;
        margin-left: auto;
      }

      .star {
        height: 15px;
        transform: translateY(1px);
      }

      .right {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;
        width: 60%;
        position: relative;

        .partner-location {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          text-align: start;
          margin: 0;
        }

        .partner-name {
          white-space: wrap;
          text-align: left;
          margin: 0;
        }

        .location {
          width: 150px;
          text-align: start;
        }

        .link {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 35px;
          height: 35px;
          border-radius: 50%;
          text-decoration: none;
          cursor: pointer;
        }
      }

      .image-container {
        width: 40%;

        .logo {
          height: 150px * $scale;
          width: 150px * $scale;
          border: 1px solid $light-grey;
          border-radius: $border-radius;

          object-fit: cover;
          margin: auto;

          @media only screen and (max-width: '765px') {
            width: 50px;
            height: 70px;
            min-width: 50px;
          }
        }
      }
    }
  }
}
