.partner-card {
  display: flex;
  justify-content: center;
  align-items: center;
  // border: 1px solid $light-grey;
  border-radius: 10px;
  width: 100%;
  aspect-ratio: 1/1;

  .image {
    width: 100%;
    height: 100%;
    aspect-ratio: 1/1;
    border-radius: 10px;
    object-fit: cover;
  }
}
