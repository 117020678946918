// Dovrebbe essere finito da testare cambiando le classi in panel
.panel {
  color: $white;

  &:hover {
    .panel-shadow {
      background: rgba($color: #000000, $alpha: 0.0);
    }
    box-shadow: 4px 4px 10px 0px rgba(0, 0, 0, 0.12);
  }
  width: 100%;
  padding: 24px * $scale 24px * $scale 16px * $scale 24px * $scale;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  flex-shrink: 0;
  border: 1px solid var(--Mid-grey, #DEDEDE);
  border-radius: 20px;
  overflow: hidden;
  position: relative;
  display: flex;
  justify-content: space-between;
  box-shadow: 4px 4px 10px 0px rgba(0, 0, 0, 0.05);

  & &-bg {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: $primary;
    background-size: cover;
    z-index: 0;
  }

  & &-shadow {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: rgba($color: #000000, $alpha: 0.1);
    z-index: 0;
  }

  & &-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    z-index: 1;
  }
  & &-bottom {
    z-index: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
    align-self: stretch;
  }
  & &-body {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px * $scale;
    align-self: stretch;
  }
  & &-footer {
    width: 100%;
    border-top: 1px solid $white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
  }
  &.w-small {
    width: 234px * $scale;
  }
  &.w-full {
    overflow: initial;
    & .panel-bg,
    & .panel-shadow {
      width: 100vw;
      left: 50%;
      transform: translateX(-50%);
    }
    & .body {
      justify-content: space-between;
    }
  }
  &.h-normal {
    height: 550px * $scale;
  }
  &.h-small {
    height: 430px * $scale;
  }
  &.nft {
    &.aspect-ratio {
      aspect-ratio: 8 / 10;
    }

    margin-bottom: 20px * $scale;
    &:last-child {
      margin-bottom: 0;
    }
  }
  &.partner {
    padding: 12px * $scale 22px * $scale;
    background-color: $light-black;
    border-radius: 8px;
    .picture {
      margin-right: 16px;
      border-radius: 8px;
      min-width: 119px * $scale;
      height: 119px * $scale;
      background-size: cover;
    }
  }
}

.clear-panel {
  .panel-shadow {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
    background: rgba($color: #000000, $alpha: 0.1);
  }
  &:hover {
    .panel-shadow {
      background: rgba($color: #000000, $alpha: 0.0);
    }
    box-shadow: 4px 4px 10px 0px rgba(0, 0, 0, 0.12);
  }
}

@media (min-width: 768px) {
  .panel {
    &.nft {
      margin-bottom: 0;
    }

    &.w-full {
      width: 100%;
      &.h-small {
        height: 550px * $scale;
      }
      border-radius: 20px;
      overflow: hidden;

      & .panel-body {
        flex-direction: row;
      }
      & .panel-shadow,
      & .panel-bg {
        left: 0;
        width: 100%;
        transform: initial;
      }
    }
  }
}

@media (min-width: 992px) {
  .partner {
    .picture {
      min-width: 148px * $scale;
    }
  }
}

@media only screen and (min-width: 1024px) {
  .nft-container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(350px * $scale, 1fr));
    gap: 20px * $scale;
  }
  .partner {
    .picture {
      min-width: 119px * $scale;
    }
  }
}
