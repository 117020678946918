.custom-stepper {
  display: flex;
  align-items: center;
  width: 100%;

  .stepper-dot {
    min-height: 20px;
    max-height: 20px;
    min-width: 20px;
    max-width: 20px;
    border: 1px solid $grey-line;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    &.current {
      border: 2px solid $black;
    }

    &.previous {
      background-color: $black;
      border: 2px solid $black;
    }
  }

  .stepper-line {
    width: 100%;
    height: 1px;
    background-color: $grey-line;

    &.previous {
      background-color: $black;
    }
  }
}
