.nft-card-fav {
  display: flex;
  flex-direction: column;
  background: $black-50;
  z-index: 3;
  cursor: pointer;

  transition-duration: 0.8s;
  position: relative;

  width: 100%;
  height: 100%;
  
  .nft-info-tag {
    width: fit-content;
    padding: 6px 12px;
    display: flex;
    justify-content: center;
    align-items: center;

    background-color: $black-65;

    border-radius: 30px;

    height: 32px;
    z-index: 4;

    &.price {
      position: absolute;
      bottom: 15px;
      left: 10px;
    }

    &.date {
      position: absolute;
      top: 10px;
      left: 10px;
    }
  }

  &.disabled {
    opacity: 0.75;
  }
}
