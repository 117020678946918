.tag {
  display: inline-flex;
  padding: 8px * $scale 12px * $scale;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border-radius: 8px;
  box-shadow: 4px 3px 10px 1px rgba(0, 0, 0, 0.1);
  background-color: $white;
  color: $black;
  &-rounded {
    border-radius: 100px;
  }
  &-black {
    background-color: $black;
    color: $white;
  }
  &-green {
    background-color: $green;
  }
}
