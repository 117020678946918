.page-item {
  &.active {
    border: 1px solid $black;
    border-radius: 5px;
  }

  .page-link {
    border-radius: 5px;
    color: black;
    background-color: white;
    border-color: $light-grey;
  }

  &:not(:first-child) {
    .page-link {
      margin-left: 0;
    }
  }
}