.slider {
  position: relative;
  width: 100%;

  display: flex;
  align-items: center;

  > .base-line {
    height: 6px;
    background-color: $grey-line; /* Assuming a grey color for the line */
    width: 100%;
  }

  > .green-line {
    height: 6px;
    background-color: $primary; /* Assuming a grey color for the line */
    width: 100%;

    position: absolute;
  }

  > .ball {
    border-radius: 50%;
    background: #fff; /* Assuming a white color for the ball */
    box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.3);

    height: 20px;
    width: 20px;

    position: absolute;
    cursor: grab;

    transform-origin: center center;

    &.max {
      transform: translateX(-100%);
    }
    &.min {
      transform: translateX(0);
    }
  }
}
