select {
  background: $white;
  background-image: url('https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@20..48,100..700,0..1,-50..200');
  background-repeat: no-repeat;
  background-position-x: 100%;
  background-position-y: 5px;
  border: 1px solid #dfdfdf;
  border-radius: 2px;
  margin-right: 10px;

  &:focus {
    box-shadow: unset !important;
    border: 0.5px solid $black-50 !important;
  }

  position: relative;

  &::after {
    content: 'ciao';
    position: absolute;
    right: 0;
  }

  &.error {
    border: 0.5px solid $error !important;
  }
}
