a.nav-link {
  font-weight: 300;
  font-size: 20px * $scale;

  cursor: pointer;
  position: relative;
  border-bottom: 1.25px solid transparent;
  height: fit-content;

  &.selected {
    font-weight: 800;
    border-bottom: 1.25px solid black;
  }
}
