form {
  display: flex;
  flex-direction: column;

  gap: 15px;

  hr {
    margin: 10px 0;
  }

  label {
    margin: 0 !important;
    font-weight: 700;
    color: $black;
    font-size: 16px * $scale;
    font-weight: 500;
    line-height: 24px * $scale;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  .form-check.form-switch {
    display: flex;
    flex-direction: row-reverse;

    justify-content: space-between;
    align-items: center;

    padding: 0;

    .form-check-input {
      margin: 0;
      cursor: pointer;

      width: 50px * $scale;
      height: 25px * $scale;
    }
  }

  .form-text {
    font-size: 12px * $scale;
    font-weight: 300;

    &.error {
      color: $error !important;
    }
  }
}
