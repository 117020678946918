.swipe-filters {
  position: relative;

  .swipe-filters-container {
    @include media-breakpoint-down(md) {
      position: fixed;
      top: 10vh;
      left: 16px;
      right: 16px;
      bottom: 10vh;
      z-index: 999;
    }

    .swipe-filters-content {
      position: absolute;

      min-width: 500px;
      top: 115%;
      z-index: 999;

      @include media-breakpoint-down(md) {
        top: 0;
        left: 0;
        right: 0;

        height: unset;
        min-width: unset;
      }

      .swipe-filters-inner {
        // height: 300px;
        // overflow: scroll;

        @include media-breakpoint-down(md) {
          height: unset;
        }
      }
    }
  }
}
