.nft-card {
  // margin-top: 50px;

  .appear-height-animation {
    height: 0;
    overflow: hidden;

    &.appear {
      animation: appear-height-animation 0.3s forwards;

      @keyframes appear-height-animation {
        from {
          height: 0;
        }
        to {
          height: 70px;
        }
      }
    }
  }

  .appear-scale-animation {
    scale: 0;

    &.appear {
      animation: appear-scale-animation 0.3s forwards;

      @keyframes appear-scale-animation {
        from {
          scale: 0;
        }
        to {
          scale: 1;
        }
      }
    }
  }

  .appear-opacity-animation {
    opacity: 0;

    &.appear {
      animation: appear-opacity-animation 0.3s forwards;

      @keyframes appear-opacity-animation {
        from {
          opacity: 0;
        }
        to {
          opacity: 1;
        }
      }
    }
  }

  .nft-share-btn {
    box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.2);

    &.animate-click {
      .material-symbols-outlined {
        animation: animate-click 0.3s infinite linear;

        @keyframes animate-click {
          0% {
            transform: scale(1);
          }
          25% {
            transform: scale(1.5);
          }
          100% {
            transform: scale(1);
          }
        }
      }
    }
  }

  .back-btn-container {
    position: absolute;
    z-index: 2;
    right: 0;
    top: 0;
    left: 0;
    .back-btn {
      padding: 13px 16px !important;
      transform: rotateY(180deg);
    }
  }

  &.nft-card-lg {
    min-width: 380px;
    max-width: 380px;
    height: 594px;

    .front-data-content {
      margin: 0 20px 0 20px;
    }
  }

  &.nft-card-md {
    max-width: 232px;
    min-width: 232px;
    height: 384px;

    .front-data-content {
      margin: 0 14px 0 14px;
    }

    .front-actions-in {
      position: absolute;
      bottom: 0;
      right: 0;
    }
  }

  &.nft-card-sm {
    min-width: 129px;
    max-width: 129px;
    height: 129px;
  }

  &.responsive-h {
    height: 100%;
    max-height: unset;
    min-height: unset;
  }

  &.responsive-w {
    width: 100%;
    max-width: unset;
    min-width: unset;
  }

  &.disabled {
    opacity: 0.75;
  }

  perspective: 1000px;
  background: transparent;

  transition-duration: 0.8s;
  position: relative;

  .nft-card-inner {
    position: relative;
    width: 100%;
    height: 100%;
    transform-style: preserve-3d;

    transition: transform 0.8s;

    .nft-card-front,
    .nft-card-back {
      border-radius: 15px;

      transition-duration: 0.3s;

      position: absolute;
      width: 100%;
      height: 100%;
      -webkit-backface-visibility: hidden; /* Safari */
      backface-visibility: hidden;
    }

    .nft-card-front {
      * {
        white-space: nowrap;
      }

      .nft-info-tag {
        width: fit-content;
        padding: 6px 12px;
        display: flex;
        justify-content: center;
        align-items: center;

        background-color: $black-65;

        border-radius: 100px;

        height: 32px;
      }

      .nft-tag {
        overflow: hidden;
        border-radius: 30px;
        padding: 8px 24px;

        position: relative;

        display: flex;
        align-items: center;
        justify-content: center;

        @keyframes border-rotate {
          0% {
            transform: rotate(0);
          }
          10% {
            transform: rotate(90deg);
          }
          40% {
            transform: rotate(105deg);
          }
          50% {
            transform: rotate(180deg);
          }
        }

        @keyframes border-expand {
          100% {
            height: 500px;
            width: 500px;
          }
        }

        &::after {
          content: '';
          z-index: -2;
          position: absolute;

          height: 200px;
          width: 20px;

          background-color: $black;

          animation: border-rotate 4s linear 0.5s infinite,
            border-expand 1s linear 2.3s forwards;
        }

        &::before {
          content: '';
          z-index: -1;

          $width: 2px;

          position: absolute;
          left: $width;
          right: $width;
          bottom: $width;
          top: $width;
          border-radius: 30px;

          background-color: $light-grey;
        }
      }

      .front-data {
        position: relative;

        box-shadow: 4px 3px 10px 1px rgba(0, 0, 0, 0.05);
        transition-duration: 0.3s;
        &:hover {
          box-shadow: 4px 3px 10px 1px rgba(0, 0, 0, 0.1);
        }

        border-radius: 15px;
        height: 100%;
        width: 100%;

        .front-data-shadow {
          border-radius: 15px;
          background-color: black;
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;

          pointer-events: none;

          background: linear-gradient(
            180deg,
            rgba(255, 255, 255, 0) 20%,
            rgba(0, 0, 0, 1) 95%
          );
        }
      }
    }

    .nft-card-back {
      transform: rotateY(180deg);
      overflow: hidden;
    }
  }

  &.rotate {
    transform: translateX(0) !important;

    max-width: 1100px;
    max-height: 1100px;
    margin-top: 50px;

    .nft-card-inner {
      transform: rotateY(180deg);
    }

    .nft-card-back {
      overflow-x: hidden;
      overflow-y: scroll;
    }

    .nft-card-front,
    .nft-card-back {
      border-radius: 0;
      box-shadow: unset;
    }
  }
}
