.lastminute {
  &:hover {
    .lastminute-shadow {
      background: linear-gradient(
        180deg,
        rgba(23, 23, 23, 0) 0%,
        rgba(23, 23, 23, 0.85) 100%
      );
    }
  }
  width: 100%;
  height: 550px;
  padding: 24px 24px 16px 24px;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  flex-shrink: 0;
  margin-right: 20px;
  margin-bottom: 20px;
  position: relative;
  display: flex;
  justify-content: space-between;

  & &-bg {
    width: 100vw;
    height: 100%;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    background-color: orange;
    background-size: cover;
    z-index: 0;
    background-image: url(http://res.cloudinary.com/dptbtrvps/image/upload/v1716208060/aaxhxbay5hmtvsfkm4xk.png);
  }

  & &-shadow {
    width: 100vw;
    height: 100%;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    background: linear-gradient(
      180deg,
      rgba(23, 23, 23, 0.1) 0%,
      rgba(23, 23, 23, 0.99) 100%
    );
    z-index: 0;
  }

  & &-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    z-index: 1;
  }
  & &-bottom {
    z-index: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
    align-self: stretch;
  }
  & &-body {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: column;
    gap: 12px;
    align-self: stretch;
  }
  & &-footer {
    width: 100%;
    border-top: 1px solid $white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
  }

  .countdown {
    display: flex;
    padding: 20px 32px;
    flex-direction: column;
    align-items: flex-start;
    border-radius: 8px;
    background-color: $white;
    color: black;
    gap: 12px;
  }
  &.smallCard {
    height: 430px;
  }
}

@media (min-width: 1024px) {
  .lastminute {
    &.smallCard {
      height: 550px;
    }
    border-radius: 20px;
    overflow: hidden;

    .lastminute-body {
      flex-direction: row;
    }
  }

  .lastminute-shadow,
  .lastminute-bg {
    left: 0;
    width: 100%;
  }
}
