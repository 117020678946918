.custom-form-switch {
  position: relative;
  height: 25px * $scale;
  width: 60px * $scale;

  display: flex;
  align-items: center;

  cursor: pointer;

  input {
    display: none;
  }

  .back {
    position: absolute;
    height: 100%;
    width: 100%;

    background-color: $mid-grey;
    border-radius: 50px;
  }

  .ball {
    position: absolute;

    display: flex;
    align-items: center;
    justify-content: center;

    height: 33px * $scale;
    width: 33px * $scale;

    background-color: $black;
    border-radius: 100%;
    border: 3px solid $white;

    user-select: none;

    transition-duration: 0.3s;
    left: 0;
  }

  .checked {
    display: none;
  }
  input:checked + .ball {
    left: calc(60px - 33px);
    background-color: $primary;

    .unchecked {
      display: none;
    }

    .checked {
      display: unset;
    }
  }
}
