input {
  font-weight: 300 !important;
}

input[type='number'],
input[type='email'],
input[type='tel'],
input[type='password'],
input[type='radio'],
input[type='switch'],
input[type='checkbox'],
input[type='text'] {
  display: flex;
  padding: 6px 10px;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  width: 100%;

  border-radius: 4px;
  border: 0.5px solid $black-50;
  background-color: $white;

  color: $black;
  font-size: 16px;
  font-weight: 300 !important;

  &:focus {
    box-shadow: unset !important;
    border: 0.5px solid $black-50 !important;
  }

  &.error {
    border: 0.5px solid $error !important;
  }
}

textarea {
  font-size: 16px;
  color: $black !important;
  font-weight: 300 !important;
  border-radius: 4px;
  border: 0.5px solid $black-50 !important;
  background-color: $white;

  &:focus {
    box-shadow: unset !important;
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

.StripeElement {
  background-color: $white;
  border: 1px solid $light;
  margin-bottom: 10px;

  height: 40px;
  padding: 10px 20px;

  font-size: 14px * $scale;

  transition-duration: 0.2s;

  &:focus {
    outline: none !important;
    border: 2px solid $light;
  }

  &:disabled {
    opacity: 0.7;
  }
}

.react-datepicker-wrapper {
  input {
    font-size: 16px;
  }
}

input[type='range']::-webkit-slider-thumb {
  -webkit-appearance: none; /* Override default look */
  appearance: none;
  margin-top: -9px; /* Centers thumb on the track */
  background-color: $primary;
  height: 25px;
  width: 25px;
}

input.input-blank {
  background-color: transparent;
  border: transparent;

  &:focus {
    box-shadow: unset !important;
    border: unset !important;
    background: unset !important;
  }

  &::placeholder {
    color: white !important;
  }

  color: white !important;
  text-align: center;

  font-size: 100px;
  font-style: normal;
  font-weight: 300 !important;
  // height: 80px;
  // line-height: 80px;
}
