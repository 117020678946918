$content-max-width: 1200px;

#web-page {
  .web-section-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 0 16px;
    overflow-x: hidden;

    .web-section-content {
      display: flex;
      flex-direction: column;
      width: 100%;
      max-width: $content-max-width;

      .documents p {
        margin-bottom: 0;
      }

      .active {
        border: 2px solid $primary-color;
      }
    }
  }

  .darken-for-hotels-header {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;

    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0.1) 20%,
      rgba(0, 0, 0, 0.9) 95%
    );
  }
}

#underline-animate {
  width: 0;

  animation: underline-animate 1.5s forwards;

  @keyframes underline-animate {
    from {
      width: 0%;
    }
    to {
      width: 105%;
    }
  }
}

#animated-landing-card {
  pointer-events: none;

  display: none;

  &.animate {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;

    height: 100vh;

    display: flex;
    align-items: center;
    justify-content: center;

    z-index: 100;

    &.card-away {
      animation: card-away 4s forwards;
      @keyframes card-away {
        from {
          transform: translateY(0);
        }
        to {
          transform: translateY(-150vw);
        }
      }
    }

    .appear {
      animation: appear forwards 5s;

      opacity: 0;
      @keyframes appear {
        0% {
          opacity: 0;
        }
        35% {
          opacity: 0;
        }
        40% {
          opacity: 1;
        }
        55% {
          opacity: 1;
        }
        100% {
          opacity: 1;
        }
        // 60% {
        //   opacity: 0;
        // }
        // 100% {
        //   opacity: 0;
        // }
      }
    }

    .flip-card-container {
      animation: go-up forwards 5s;

      opacity: 0;
      @keyframes go-up {
        0% {
          opacity: 0;
          transform: translateY(100vw) rotate(-15deg) scale(0.9);
        }
        5% {
          opacity: 1;
        }
        20% {
          transform: translateY(0vw) rotate(-15deg) scale(0.9);
        }
        40% {
          transform: translateY(0vw) rotate(-10deg) scale(1);
        }
        100% {
          transform: translateY(0vw) rotate(-10deg) scale(1);
          opacity: 1;
        }
        // 75% {
        //   opacity: 1;
        // }
        // 100% {
        //   opacity: 0;
        //   transform: translateY(-100vw) rotate(-15deg) scale(0.9);
        // }
      }
    }

    .flip-card {
      width: 40vw;
      max-width: 300px;
      aspect-ratio: 3/4;

      background-color: transparent;
      perspective: 1000px; /* Remove this if you don't want the 3D effect */
    }

    .flip-card-inner {
      position: relative;
      width: 100%;
      height: 100%;
      text-align: center;
      transition: transform 0.8s;
      transform-style: preserve-3d;

      animation: rotating forwards 5s;

      @keyframes rotating {
        0% {
          transform: rotateY(0);
        }
        20% {
          transform: rotateY(0);
        }
        50% {
          transform: rotateY(360deg);
        }
        100% {
          transform: rotateY(360deg);
        }
      }
    }

    // .flip-card:hover .flip-card-inner {
    //   transform: rotateY(180deg);
    // }

    .flip-card-front,
    .flip-card-back {
      position: absolute;
      width: 100%;
      height: 100%;
      -webkit-backface-visibility: hidden; /* Safari */
      backface-visibility: hidden;
    }

    .flip-card-back {
      color: white;
      transform: rotateY(180deg);
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;

      border-radius: 35px;

      box-shadow: -30px 60px 10px 10px rgba(0, 0, 0, 0.2);
    }
  }
}

.partner-img {
  * {
    transition-duration: 0.3s;
  }

  &:hover {
    .partner-bg {
      transform: scale(1.2);
    }
  }
}

.py-32 {
  padding-top: 32px;
  padding-bottom: 32px;
}
