$default: #344675 !default;
$transparent-bg: transparent !default;

// design library
$primary-color: #8ce786 !default;
$black: #000000 !default;
$dark-grey: #333333 !default;
$mid-grey: #cfcfcf !default;
$white: #ffffff !default;

// Takyon colors
$primary: #8ce786;
$primary-light: #e6fae6;
$black: #171717;
$light-black: #242424;
$dark-grey: #9d9d9d;
$grey-line: #d9d9d9;
$mid-grey: #eaeaea;
$light-grey: #f5f5f5;
$white: #ffffff;
$hover-primary: #85db7f;
$hover-mid-grey: #d3d3d3;
$black-50: #00000080;
$black-65: #000000a6;
$error: #f31700;
$success: #37b400;
$gold: #ffd977;
$blue: #4282ff;

$theme-colors: () !default;
$theme-colors: map-merge(
  (
    'default': $default,
    'primary': $primary-color,
    'primary-light': $primary-light,
    'secondary': $mid-grey,
    'mid-grey': $mid-grey,
    'black': $black,
    'light-black': $light-black,
    'dark-grey': $dark-grey,
    'light-grey': $light-grey,
    'grey-line': $light-grey,
    'white': $white,
    'hover-primary': $hover-primary,
    'hover-mid-grey': $hover-mid-grey,
    'black-50': $black-50,
    'black-65': $black-65,
    'danger': $error,
    'success': $success,
    'neutral': $white,
    'gold': $gold,
    'red': $error,
    'blue': $blue,
  ),
  $theme-colors
);

@mixin generate-color-classes {
  @each $color-name, $color-value in $theme-colors {
    .#{$color-name}-color {
      color: $color-value !important;
      fill: $color-value !important;
    }

    .#{$color-name}-background {
      background-color: $color-value !important;
    }

    .#{$color-name}-border {
      border: 1px solid $color-value !important;
    }

    .#{$color-name}-hover {
      &:hover {
        background-color: $color-value !important;
      }
    }
  }
}

@include generate-color-classes;
