.onboarding-steps {
  display: flex;
  width: 100%;
  justify-content: space-between; /* Aligns items with space between them */
  gap: 20px; /* Adjust the gap as needed */
}

/* Styling for the line between circles */
.onboarding-steps .border-bottom {
  flex-grow: 1;
  border-bottom: 2px solid #fff;
  height: 10px;
  // width: 200px;
  min-width: 100%;
}


.circle {
  width: 20px;
  height: 20px;
  border: 2px solid #fff; 
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: relative;
  transition: background-color 0.3s ease;
}

.inner-circle {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  transition: width 0.3s ease, height 0.3s ease;
}

.circle.active .inner-circle {
  width: 8px; 
  height: 8px;
  background-color: #fff;
}
