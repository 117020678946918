@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@0,400..900;1,400..900&display=swap");

$font-primary: "Poppins", sans-serif;
$font-play: "Playfair Display", serif;

.font-play {
  font-family: $font-play;
}

.h1-xxlarge {
  font-size: 350px * $scale;
  line-height: 230px;
  letter-spacing: -20px;

  @include media-breakpoint-down(lg) {
    line-height: 60px;
    font-size: 130px * $scale;
    letter-spacing: -5px;
  }
  @include media-breakpoint-down(md) {
    line-height: 90px;
    font-size: 200px * $scale;
  }
}

.h1-xlarge {
  font-size: 141px * $scale;
  line-height: 110px;
  @include media-breakpoint-down(xxl) {
    line-height: 80px;
    font-size: 90px * $scale;
  }
  @include media-breakpoint-down(xl) {
    line-height: 50px;
    font-size: 60px * $scale;
  }
}

.h1-large {
  font-size: 70px * $scale;
  line-height: 50px;

  @include media-breakpoint-down(lg) {
    font-size: 55px * $scale;
    line-height: 45px;
  }
  @include media-breakpoint-down(md) {
    font-size: 48px * $scale;
    line-height: 35px;
  }
}
.h1 {
  font-size: 38px * $scale;
  @include media-breakpoint-down(lg) {
    font-size: 32px * $scale;
  }
  @include media-breakpoint-down(md) {
    font-size: 25px * $scale;
  }
}
.h2 {
  font-size: 32px * $scale;
  @include media-breakpoint-down(lg) {
    font-size: 27px * $scale;
  }
  @include media-breakpoint-down(md) {
    font-size: 22px * $scale;
  }
}
.h3 {
  font-size: 30px * $scale;
  @include media-breakpoint-down(md) {
    font-size: 30px * $scale;
  }
}
.h4 {
  font-size: 24px * $scale;
  @include media-breakpoint-down(lg) {
    font-size: 20px * $scale;
  }
  @include media-breakpoint-down(md) {
    font-size: 16px * $scale;
  }
}
// .h5 {
//   font-size: 24px * $scale;
//   @include media-breakpoint-down(md) {
//     font-size: 20px * $scale;
//   }
// }

.bodytext-lg {
  font-size: 25px * $scale;
  @include media-breakpoint-down(lg) {
    font-size: 22px * $scale;
  }
  @include media-breakpoint-down(md) {
    font-size: 18px * $scale;
  }
}
.bodytext {
  font-size: 20px * $scale;
  @include media-breakpoint-down(md) {
    font-size: 18px * $scale;
  }
  b {
    font-weight: 500 !important;
  }
}
.bodytext-sm {
  font-size: 16px * $scale;
  @include media-breakpoint-down(md) {
    font-size: 14px * $scale;
  }
}
.bodytext-sm-xs {
  font-size: 14px * $scale;
  @include media-breakpoint-down(md) {
    font-size: 13px * $scale;
  }
}
.bodytext-xs {
  font-size: 12px * $scale;
  @include media-breakpoint-down(md) {
    font-size: 12px * $scale;
  }
}

.linktext {
  font-size: 18px * $scale;
  text-decoration: underline;
  @include media-breakpoint-down(md) {
    font-size: 16px * $scale;
  }
}
.linktext-sm {
  font-size: 16px * $scale;
  text-decoration: underline;
  @include media-breakpoint-down(md) {
    font-size: 14px * $scale;
  }
}

.text-underline {
  text-decoration: underline;
}

// .bold {
//   font-weight: 700;
// }
.medium {
  font-weight: 500 !important;
}
.regular {
  font-weight: 400 !important;
}
.light {
  font-weight: 300 !important;
}
// .extralight {
//   font-weight: 275;
// }

@media only screen and (min-width: 769px) and (max-height: 600px) {
  .h1-xxlarge {
    font-size: 280px * $scale;
    line-height: 184px;
    letter-spacing: -16px;
  }
  .h1-xlarge {
    font-size: 113px * $scale;
    line-height: 88px;
  }
  .h1-large {
    font-size: 56px * $scale;
    line-height: 40px;
  }
  .h1 {
    font-size: 30.4px * $scale;
  }
  .h2 {
    font-size: 25.6px * $scale;
  }
  .h3 {
    font-size: 24px * $scale;
  }
  .h4 {
    font-size: 19.2px * $scale;
  }
  .bodytext-lg {
    font-size: 20px * $scale;
  }
  .bodytext {
    font-size: 16px * $scale;
  }
  .bodytext-sm {
    font-size: 12.8px * $scale;
  }
  .bodytext-sm-xs {
    font-size: 11.2px * $scale;
  }
  .bodytext-xs {
    font-size: 9.6px * $scale;
  }
  .linktext {
    font-size: 14.4px * $scale;
  }
  .linktext-sm {
    font-size: 12.8px * $scale;
  }
}
