$scale: 0.6;

// Config
@import "./Config/colors";
@import "./Config/shapes";

// Bootstrap
@import "~bootstrap/scss/bootstrap";

@import "./Config/fonts";
@import "./Config/fs-fonts";

//Pages
@import "./Pages/main";
@import "./Pages/web";

// Components
@import "./Components/wallet";
@import "./Components/nftcard";
@import "./Components/nftcard2";
@import "./Components/nftcardfav";
@import "./Components/datepicker";
@import "./Components/stepper";
@import "./Components/partner";
@import "./Components/filters";
@import "./Components/pagination";
@import "./Components/accordion";
@import "./Components/panel";
@import "./Components/takcard";
@import "./Components/tag";
@import "./Components/lastminutes";
@import "./Components/panelbutton";
@import "./Components/tab";
@import "./Components/slider";
@import "./Components/multiselect";
@import "./Components/chip";
@import "./Components/nav";

// Dom
@import "./Dom/button";
@import "./Dom/navlink";
@import "./Dom/hr";
@import "./Dom/form";
@import "./Dom/select";
@import "./Dom/input";
@import "./Dom/checkbox";
@import "./Dom/switch";
@import "./Dom/progress";
@import "./Dom/separators";

// Functions
@import "./Functions/sizes";
@import "./Functions/fontweight";

// Icons
@import "material-icons/iconfont/material-icons.scss";
@import "material-symbols";

// Date picker
@import "react-datepicker/dist/react-datepicker.css";

body {
  font-family: $font-primary;
}
